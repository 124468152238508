
































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import House from "~/models/House"
import router, { houseContextRoutes } from "@/router"
import { state } from "@/store"
import { Route } from "vue-router"
import PromptIgnorePendingMessage from "@/components/modals/PromptIgnorePendingMessage.vue"
import DelayedEmailButton from "@/components/house/DelayedEmailButton.vue"
import EventBus from "~/utils/EventBus"

type Tab = {
  route: string
  name: string
  icon: string
}

@Component({
  components: {
    PromptIgnorePendingMessage,
    DelayedEmailButton,
    Toolbar: () => import("@/layouts/default/Toolbar.vue"),
  },
})
export default class HouseSheet extends Vue {
  @Prop({ default: () => new House() }) house!: House
  loading = false
  activeTab = 0
  tabs: Tab[] = [
    {
      route: "description",
      name: "Description",
      icon: "FileText",
    },
    {
      route: "agencies",
      name: "Agences",
      icon: "Agency",
    },
    {
      route: "verification",
      name: "Vérifications",
      icon: "Verification",
    },
    {
      route: "visits",
      name: "Visites",
      icon: "mdi-account-supervisor-circle-outline",
    },
    {
      route: "offers",
      name: "Offres",
      icon: "mdi-file-document-multiple-outline",
    },
    {
      route: "messages",
      name: "Messages",
      icon: "MessageCircle",
    },
  ]

  mounted() {
    this.loadHouse()
    // Reload the house if house.visits has been updated
    EventBus.on("visit-updated", this.loadHouse)
  }

  destroy() {
    EventBus.off("visit-updated")
  }

  async handlePendingMessage(to: Route, from: Route, next: () => void) {
    // Determine if next route is still in the same 'house' context
    const contextIsSame = !!to.matched.find((route) => {
      return route.name ? ~houseContextRoutes.indexOf(route.name) : false
    })
    const houseIdChanged = from.params.houseId !== to.params.houseId

    // Open prompt to ignore Pending message if applicable unless load next page
    const prompt = this.$refs
      .promptIgnorePendingMessage as PromptIgnorePendingMessage
    if (state.currentMessage !== "" && (!contextIsSame || houseIdChanged))
      prompt.open(next)
    else next()
  }

  async beforeRouteLeave(to: Route, from: Route, next: () => void) {
    await this.handlePendingMessage(to, from, next)
  }

  async beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    await this.handlePendingMessage(to, from, next)
  }

  get filteredTabs() {
    const { houseId } = this.$route.params
    if (houseId != "new") {
      return this.tabs
    } else {
      return [
        {
          route: "verification",
          name: "Création",
          icon: "FileChecked",
        },
      ]
    }
  }

  get goBackText() {
    const { conversationId } = this.$route.params
    return conversationId ? "Retour aux messages" : "Retour à la liste"
  }

  reloadHouseSheet() {
    this.house.id = null
    console.log("reloadHouseSheet")
    this.loadHouse()
  }

  @Watch("$route.params.houseId")
  loadHouse() {
    this.setActiveTabFromRoute()
    const { houseId } = this.$route.params
    if (houseId == "new") return

    this.loading = true
    this.house.id = houseId ? +houseId : null
    this.house.deal_id = state.currentDealId()
    this.house.fetch()
    this.house.once("loaded", () => {
      if (this.tabs[this.activeTab].route == "messages")
        this.house.unseen_messages = false
      this.loading = false
    })
  }

  goBack() {
    const elements = this.$route.path.split("/")
    const relativeIndexOfActiveTab =
      elements.indexOf(this.filteredTabs[this.activeTab].route) -
      elements.length
    const base = elements.slice(0, relativeIndexOfActiveTab - 2).join("/")
    const newRoute = `${base}${this.routeQuery()}`
    router.push(newRoute)
  }

  @Watch("$route")
  setActiveTabFromRoute() {
    const elements = this.$route.path.split("/")
    let index = 0
    for (const tab of this.filteredTabs) {
      if (elements.includes(tab.route)) break
      index++
    }
    this.activeTab = index
  }
  changeTab(tab: Tab) {
    const elements = this.$route.path.split("/")
    const relativeIndexOfActiveTab =
      elements.indexOf(this.filteredTabs[this.activeTab].route) -
      elements.length
    const base = elements.slice(0, relativeIndexOfActiveTab).join("/")
    const newRoute = `${base}/${tab.route}${this.routeQuery()}`
    if (newRoute != this.$route.fullPath) router.push(newRoute)
  }
  routeQuery() {
    // return the part after '?' (including the interrogation mark)
    return this.$route.fullPath.slice(this.$route.path.length)
  }
  closeAgencyCard() {
    this.changeTab({
      route: "agencies",
      name: "Agences",
      icon: "Agency",
    })
  }
}
